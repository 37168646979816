//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoData from '~/components/no-data'
import SubMenu from './components/subMenu'
import ContentCard from '../content-card/index.vue'
import pFooter from '../p-footer/footer.vue'
import mock from '~/mock'
export default {
  components: {
    SubMenu,
    NoData,
    ContentCard,
    pFooter,
  },
  props: {
    showNoMoreText: {
      type: Boolean,
      default: false,
    },
    pageContentNav: {
      type: Boolean,
      default: false,
    },
    // 首页设置的相关信息
    homeSettingInfo: {
      type: Object,
      default: () => {},
    },
    // 首页导航菜单
    menuList: {
      type: Array,
      default: () => [],
    },
    // 首页的数据，注意：这里的数据来自两个接口不同的数据，一个是包含cta和content的，一个是筛选后的只包含content的
    homeListData: {
      type: Object,
      default: () => {},
    },
    isStatistics: {
      type: [String, Number],
      default: '1',
    },
    widthflag: {
      type: String,
      default: '',
    },
    host: {
      type: String,
      default: '',
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number || String,
      default: 12 || '12',
    },
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
        index: 'id',
      },
      changWidth: '',
      isSearch: false,
      mock: mock,
      isOverSearch: true,
      contentList: [],
      heightlight: [],
      highlightType: -1,
      searchVal: '',
      currentClassification: { label: '首页', id: -1 },
      currentType: 'click_menu',
      navBarFlag: false,
      logoOpr: {
        is_top_logo: 0,
        logo_image: '',
        logo_url: '',
        logo_url_protocol: '',
      },
      activeFlag: 0,
      activeIndexChange: '首页',
      searchClickFlag: false, //是否点击搜索过
      searchNum: 0,
      currentPage1: 1,
    }
  },
  computed: {
    showHeightLight() {
      return this.heightlight.length !== 0
    },
    iframeLink() {
      return `${this.homeSettingInfo.cta_url}&pType=contenthp&pId=-1&release=1&isStatistics=${this.isStatistics}`
    },
  },
  watch: {
    homeListData: {
      handler(val) {
        if (val && val.list) {
          this.contentList = JSON.parse(JSON.stringify(val.list)) || []
          this.heightlight = val.heightlight || []
          this.highlightType = val.is_heightlight
          this.handleZoomCard() // 处理突出显示盒子后的占位元素
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.pageContentNav) {
      this.activeIndexChange = null
    }
    if (
      this.homeSettingInfo.is_top_logo &&
      this.homeSettingInfo.is_top_logo == 1
    ) {
      this.logoOpr.is_top_logo = this.homeSettingInfo.is_top_logo
      this.logoOpr.logo_image = this.homeSettingInfo.logo_image
      this.logoOpr.logo_url = this.homeSettingInfo.logo_url
      this.logoOpr.logo_url_protocol = this.homeSettingInfo.logo_url_protocol
    }
    //console.log('homeSettingInfo', this.logoOpr)
    this.changeNavContent()
    this.handleSetNavStyle()
    this.handleSetPagingStyle()
    //console.log('homeSettingInfo', this.homeSettingInfo)
  },
  beforeMount() {
    // window.addEventListener('scroll', this.handleScroll, true)
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    //处理分页样式
    handleSetPagingStyle() {
      if (this.homeSettingInfo.paging_setting_status == 2) return
        this.$nextTick(() => {
          let obj={
            default_background_color:'#fff',
            default_words_color:'#fff',
            page_selected_color:'#fff',
            words_selected_color:'#fff'
          }
          const {
            default_background_color,
            default_words_color,
            page_selected_color,
            words_selected_color,
          } = Object.assign(obj, this.homeSettingInfo.paging_setting)
          let elPage = [
              ...document.querySelectorAll('.el-pager > .number'), // labelspan
            ],
            active = [
              ...document.querySelectorAll('.active'), // labelspan
            ],
            more = [
              ...document.querySelectorAll('.el-pager > .more'), // labelspan
            ],
            btnPrev = [
              ...document.querySelectorAll('.el-pagination > .btn-prev'), // labelspan
            ],
            btnNext = [
              ...document.querySelectorAll('.el-pagination > .btn-next'), // labelspan
            ]
          elPage.forEach((dom) => {
            dom.style.backgroundColor = default_background_color
            dom.style.color = default_words_color
          })
          more.forEach((dom) => {
            dom.style.backgroundColor = default_background_color
            dom.style.color = default_words_color
          })
          active.forEach((dom) => {
            dom.style.backgroundColor = page_selected_color
            dom.style.color = words_selected_color
          })
          btnPrev.forEach((dom) => {
            dom.style.backgroundColor = default_background_color
            dom.style.color = default_words_color
          })
          btnNext.forEach((dom) => {
            dom.style.backgroundColor = default_background_color
            dom.style.color = default_words_color
          })
        })
     
    },
    // 处理nav样式
    handleSetNavStyle() {
      this.$nextTick(() => {
        let backgroundColor = this.homeSettingInfo.background_color || '#fff', // 导航背景色
          textColor = this.homeSettingInfo.text_color || '#2B3D4F', // 导航文字颜色
          pNavSearchs = [...document.querySelectorAll('.p-nav-search')], // 导航搜索warp
          firstMenuTitle = [
            ...document.querySelectorAll('.el-menu > li .menu-label'), // labelspan
          ],
          firstMenuTitle1 = [
            ...document.querySelectorAll('.el-menu > li .menu-label>span'), // labelspan
          ],
          elMenu = [...document.querySelectorAll('.submenu-wrap > .el-menu')], // elementui 导航元素
          bottom_line = [...document.querySelectorAll('.bottom_line')]
        // 设置颜色 start
        elMenu.forEach((dom) => {
          dom.style.backgroundColor = backgroundColor
        })
        bottom_line.forEach((dom) => {
          dom.style.backgroundColor = textColor
        })
        pNavSearchs.forEach((dom) => {
          dom.style.backgroundColor = backgroundColor
        })
        firstMenuTitle.forEach((dom) => {
          dom.setAttribute(
            'style',
            `opacity: 0.6 ;color: ${textColor} !important`
          )
          // dom.setAttribute('style', `color: ${textColor} !important`)
          if (dom.nextSibling != null) {
            dom.nextSibling.setAttribute(
              'style',
              `opacity: 0.6;color: ${textColor} !important;`
            )
          }
        })
        firstMenuTitle1.forEach((dom) => {
          dom.setAttribute('style', `color: ${textColor} !important`)
          // dom.setAttribute('style', `color: ${textColor} !important`)
        })
      })
      // 设置颜色 end
    },
    // 处理滚动
    // handleScroll() {
    //   var scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop
    //   if (scrollTop > this.$refs.pNavSearch.offsetTop + 78) {
    //     this.isOverSearch = true
    //   } else {
    //     this.isOverSearch = true
    //   }
    // },
    // 点击搜索按钮，有可能会在分类，也有可能会在首页
    handleClickSearch() {
      this.searchNum++
      this.activeFlag = this.searchNum
      this.currentType = 'search_menu'
      this.searchClickFlag = true
      this.activeIndexChange = 1
      this.$emit(
        'onGetMenu',
        this.currentClassification,
        'search_menu',
        this.searchVal
      )
    },
    // 点击单个meunt item
    onGetMenu(item, name) {
      this.currentClassification = item
      this.currentType = 'click_menu'
      this.$emit('onGetMenu', item, 'click_menu', name)
    },
    // 点击search small icon
    handleClickSearchWrap(flag) {
      if (this.searchClickFlag) {
        this.activeIndexChange = null
      }
      //this.searchVal = ''
      this.isSearch = flag

      this.$nextTick(() => {
        this.autofocusFn()
      })
      this.changWidth = 'width:501px;transition: width 5s'
      // if (!flag && this.searchClickFlag) {
      //   let item = {
      //     id: -1,
      //   }
      //   ;(this.currentClassification = { label: '首页', id: -1 }),
      //     this.$emit('onGetMenu', item, 'click_menu')
      // }
      this.handleSetNavStyle()
      this.searchClickFlag = false
    },
    // js动态处理突出显示，创建占位元素
    handleZoomCard() {
      if (this.showHeightLight) {
        let template = {
            url: '',
            title: '占位',
            creator: '占位',
            create_time: '占位',
            uv: '占位',
            need_zoom: 0,
            is_define: true,
          },
          is = [0, 1, 4, 5]
        is.forEach((item) => {
          this.contentList.splice(item, 0, template)
        })
      }
    },
    //自动聚焦
    autofocusFn() {
      if (this.isSearch) {
        if (this.isOverSearch) {
          this.$refs.autofocusInput1.focus()
        } else {
          this.$refs.autofocusInput.focus()
        }
      }
    },
    //判断是否在详情页
    changeNavContent() {
      // console.log('=============>', sessionStorage.getItem('postVal'))
      if (this.homeListData) {
        // console.log('=============>', sessionStorage.getItem('postVal'))
        this.navBarFlag = true
        if (JSON.parse(sessionStorage.getItem('postVal'))) {
          if (!JSON.parse(sessionStorage.getItem('postVal')).name) {
            this.activeIndexChange = JSON.parse(
              sessionStorage.getItem('postVal')
            ).item.label
          } else {
            this.activeIndexChange = null
          }

          //console.log('=============>', this.activeIndexChange)
          this.onGetMenu(
            JSON.parse(sessionStorage.getItem('postVal')).item,
            JSON.parse(sessionStorage.getItem('postVal')).name
          )
        }
      } else {
        this.navBarFlag = false
      }
    },
    handleCurrentChange(val) {
      this.handleSetPagingStyle()
      this.currentPage1 = val
      this.$emit('handleCurrentChange', val)
    },
  },
}
