//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isPc: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: '暂未搜索到相关数据',
    },
  },
}
