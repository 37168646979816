var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:['p-nav-wrap', _vm.widthflag],style:({
      paddingBottom: _vm.homeListData && _vm.homeListData.total ? '0rem' : '0.32rem',
    })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navBarFlag),expression:"navBarFlag"}],class:[
        { noDataClass: _vm.contentList.length == 0 },
        'p-content-list-wrap' ],style:({
        minHeight: _vm.showNoMoreText
          ? 'calc(100vh - 2.08rem)'
          : 'calc(100vh - 2.4rem)',
      })},[(_vm.contentList.length === 0)?_c('div',{staticClass:"no-data mb24"},[_c('NoData')],1):_c('div',{staticClass:"list p-relative"},[_vm._l((_vm.contentList),function(item,index){return _c('div',{key:index},[_c('content-card',{class:[{ mr32: (index + 1) % 4 }, { mt32: index > 3 }],attrs:{"home-setting-info":_vm.homeSettingInfo,"info":item,"resouce-type":item.type,"is-statistics":_vm.isStatistics,"host":_vm.host},on:{"changeNavContent":_vm.changeNavContent}})],1)}),_vm._v(" "),(_vm.showHeightLight)?_c('div',{staticClass:"outstanding-wrap p-absolute"},[(_vm.homeSettingInfo.is_heightlight === 2)?_c('div',[_c('iframe',{attrs:{"id":"iframe","src":_vm.iframeLink,"width":"100%","height":"100%"}})]):_c('div',{staticClass:"wp100 hp100 banner-high"},[_c('el-carousel',{staticClass:"el-banner banner-high wp100 hp100",attrs:{"trigger":"click","arrow":_vm.heightlight && _vm.heightlight.length > 1 ? 'hover' : 'never',"autoplay":true,"indicator-position":_vm.heightlight && _vm.heightlight.length > 1 ? '' : 'none'}},_vm._l((_vm.heightlight),function(item,index){return _c('el-carousel-item',{key:index},[_c('content-card',{attrs:{"home-setting-info":_vm.homeSettingInfo,"info":item,"card-type":"zoom","resouce-type":item.type,"is-statistics":_vm.isStatistics,"host":_vm.host}})],1)}),1)],1)]):_vm._e()],2),_vm._v(" "),(false)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.showNoMoreText && _vm.contentList.length && _vm.contentList.length > 8
        ),expression:"\n          !showNoMoreText && contentList.length && contentList.length > 8\n        "}],staticClass:"bottom-last loading-more"},[_vm._m(0)]):_vm._e(),_vm._v(" "),(false)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNoMoreText),expression:"showNoMoreText"}],staticClass:"bottom-last mb32"},[_c('div',{staticClass:"left"}),_vm._v(" "),_c('div',{staticClass:"text"},[_vm._v("这已经是最底部了")]),_vm._v(" "),_c('div',{staticClass:"right"})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"p-nav-search",class:{
        flex: _vm.isSearch,
        jcc: _vm.isSearch,
        'p-fixed-page': true,
        'show-p-fixed-page': _vm.isOverSearch,
      }},[_c('div',{staticClass:"p-nav-search-box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSearch),expression:"!isSearch"}],class:['submenu-wrap', { 'hide-submenu': _vm.isSearch }]},[_c('SubMenu',{attrs:{"menu-list":_vm.menuList,"props":_vm.defaultProps,"logo-opr":_vm.logoOpr,"active-flag":_vm.activeFlag,"active-index-change":String(_vm.activeIndexChange)},on:{"getmenu":_vm.onGetMenu}})],1),_vm._v(" "),_c('div',{staticClass:"search-wrap",style:({
            margin: _vm.isSearch ? '0 auto' : '',
          })},[(!_vm.isSearch)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleClickSearchWrap(true)}}},[_c('i',{staticClass:"font_color_desc font_family icon-sousuo2",staticStyle:{"font-size":"0.24rem"}})]):_c('div',{staticClass:"search-input"},[_c('div',{staticClass:"cancel-wrap flex jcc alc pointer",on:{"click":function($event){return _vm.handleClickSearchWrap(false)}}},[_c('img',{attrs:{"src":require("assets/images/close@2x.png"),"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"ipt-wrap",style:(_vm.changWidth)},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchVal),expression:"searchVal"}],ref:"autofocusInput1",attrs:{"type":"text","placeholder":"请输入搜索关键字"},domProps:{"value":(_vm.searchVal)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleClickSearch.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchVal=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"search-btn flex jcc alc pointer",on:{"click":_vm.handleClickSearch}},[_c('img',{attrs:{"src":require("assets/images/icon-search@2x.png"),"alt":""}})])])])])])]),_vm._v(" "),(_vm.homeListData && _vm.homeListData.total)?_c('div',{staticClass:"content-pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.homeListData.total,"current-page":_vm.currentPage1,"page-size":_vm.homeListData && _vm.homeListData.page_size
          ? _vm.homeListData.page_size
          : _vm.pageSize},on:{"update:currentPage":function($event){_vm.currentPage1=$event},"update:current-page":function($event){_vm.currentPage1=$event},"current-change":_vm.handleCurrentChange}})],1):_vm._e(),_vm._v(" "),(
      (!_vm.showNoMoreText ||
        _vm.showNoMoreText ||
        _vm.contentList.length === 0 ||
        _vm.contentList.length <= 8) &&
      _vm.navBarFlag
    )?_c('p-footer',{attrs:{"home-setting-info":_vm.homeSettingInfo}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text"},[_c('img',{attrs:{"src":require("assets/images/loading.png")}}),_vm._v(" 加载中...\n        ")])}]

export { render, staticRenderFns }